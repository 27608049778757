import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label, Modal, Input, FormGroup } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";

import {
  apiError,
  addGroup,
  deleteGroup,
  updateGroup,
  getWardOptions,
  getLocalbodyOptions,
  getLocalBody,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import { getLocalbody } from "../../../helpers/globalFunctions";
import "./group.scss";


const Group = (props) => {
  //  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedPrivilage, setSelectedPrivilage] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [userObject, setUserObject] = useState({});
  const [groupIdTobeUpdated, setGroupIdToBeUpdated] = useState(null);
  const [groupIdToBeDeleted, setGroupIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [groupDataForTable, setgroupDataForTable] = useState([]);
  const [accountType, setAccountType] = useState("");
  const [selectedLocalbody, setselectedLocalbody] = useState(null);
  const [selectedWard, setSelectedWard] = useState(null);
  const [selectedDistrict, setselectedDistrict] = useState(null);
  const [groupname, setGroupname] = useState("");
  const [groupcode, setGroupcode] = useState("");
  const [districtOptions, setDistrictOptions] = useState([]);
  const [groupObject, setgroupObject] = useState({});
  const [localbodycode, setLocalbodycode] = useState({});

  const [passwordObject, setPasswordObject] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [incentiveOptions, setIncentiveOptions] = useState([]);
  const [selectedIncentive, setSelectedIncentive] = useState(null);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [errors, setErrors] = useState({
    districtError: "",
    localbodyError: "",
    wardError: "",
  });
  const { groups, addingGroup, addGroupResponse, deleteGroupResponse, updateGroupResponse, error } = useSelector((state) => state.groups);
  const [selectedChart, setSelectedChart] = useState(null);
  const [chartOptions, setChartOptions] = useState([]);
  const [upiname, setUpiname] = useState("");

  const { localbodyOptions, localbody } = useSelector((state) => state.localbodies);
  // const { districtOptions } = useSelector((state) => state.districts);

  // const districtsOptions = useSelector(
  //   (state) => state.districts.districtsOptions
  // );

  const privilagesOptions = useSelector((state) => state.privilages.privilagesOptions);
  const companiesOptions = useSelector((state) => state.companies.companiesOptions);

  const { wardOptions } = useSelector((state) => state.wards);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const dispatch = useDispatch();
  const formRef = useRef();

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    handleTableData();
    fetch_all_incentives();
    fetch_district(currentLocalbody);
    fetch_chart();
  }, [currentLocalbody]);

  function handleTableData() {
    var url = `${API_URL}group/list?localbody_id=` + currentLocalbody;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let groupsData = [];
        result &&
          result.map((item, index) => {
            // if (item.group_ward != null) {
            //   item.ward = (
            //     <div
            //       style={{ display: "flex", justifyContent: "space-between" }}
            //     >
            //       {item.group_ward?.map((data) => {
            //         return <Chip size="small" label={data.ward_name}></Chip>;
            //       })}
            //     </div>
            //   );
            // }
            item.id = index + 1;
            item.time = moment(item.group_time, "HHmmss").format("hh:mm a"); // 24hours
            item.date = moment(item.group_date).format("DD-MM-YYYY");
            //   item.name1 = `${item.firstName} ${item.lastName}`;

            //   item.privilage1 = item.privilage && item.privilage.name;
            //   item.company1 = item.company && item.company.name;
            //   item.branch1 = item.branch && item.branch.name;
            if (item.group_localbody_name_id != null) {
              item.localbody = item.group_localbody_name_id.localbody_name;
            }
            if (item?.group_updatedby) {
              item.addedby = item.group_updatedby.firstName;
            } else {
              if (item.group_addedby != null) {
                item.addedby = item.group_addedby.firstName;
              }
            }

            item.ward = (
              <div>
                <ul>
                  {item.group_ward &&
                    item.group_ward.map((element, index) => {
                      return <li>{element.ward_name}</li>;
                    })}
                </ul>
              </div>
            );
            let incentive = item.group_incentive || {};
            item.incentiveName = incentive?.incentive_name;
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <i
            className="uil-key-skeleton"
            style={{ fontSize: "1.3em", cursor: "pointer" }}
            onClick={() => {
              preUpdateUserPassword(item);
            }}
          ></i> */}
                <i
                  className="uil-edit-alt"
                  style={{
                    fontSize: "1.2em",
                    cursor: "pointer",
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => {
                    toTop();
                    preUpdateGroup(item);
                  }}></i>
                <i
                  className="uil-trash-alt"
                  style={{ fontSize: "1.2em", cursor: "pointer" }}
                  onClick={() => {
                    setGroupIdToBeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}></i>
              </div>
            );
            groupsData.push(item);
          });
        setgroupDataForTable(groupsData);
      });
  }
  function fetch_all_incentives() {
    axios
      .get(`${API_URL}incentive/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var incentive_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.incentive_name,
              value: el._id,
            };
          });

        setIncentiveOptions([
          {
            options: incentive_data,
          },
        ]);
      });
  }
  const fetch_district = (localbody) => {
    axios
      .get(`${API_URL}api/staff/districts?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var district_data = [];
        if (res.data.success && res.data.success === true) {
          district_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.district_name,
                value: el._id,
              };
            });
        } else {
          district_data = [];
        }

        setDistrictOptions([
          {
            options: district_data,
          },
        ]);
      });
  };

  function fetch_chart() {
    axios
      .get(`${API_URL}group/upi/list?localbody=` + currentLocalbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var chart_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.acc_chart_name,
              value: el?._id,
            };
          });
        setChartOptions([{ options: chart_data }]);
      });
  }
  // useEffect(() => {
  //   if (addGroupResponse.type === "success") {
  //     //dispatch(getGroups());
  //     handleTableData();
  //     toastr.success(addGroupResponse.message);
  //     setSelectedPrivilage({});
  //     setSelectedCompany(null);
  //     setSelectedBranch(null);
  //     // setSelectedIncentive(null);
  //   } else if (addGroupResponse.type === "failure") {
  //     toastr.error(error.data.message, addGroupResponse.message);
  //     // toastr.error(addGroupResponse.message);
  //   }
  // }, [addGroupResponse]);
  // useEffect(() => {
  //   if (deleteGroupResponse.type === "success") {
  //     //dispatch(getGroups());
  //     handleTableData();
  //     toastr.success(deleteGroupResponse.message);
  //     setGroupIdToBeDeleted(null);
  //   } else if (deleteGroupResponse.type === "failure") {
  //     toastr.error(error.data.message, deleteGroupResponse.message);
  //   }
  // }, [deleteGroupResponse]);

  // useEffect(() => {
  //   if (updateGroupResponse.type === "success") {
  //     //dispatch(getGroups());
  //     handleTableData();
  //     setShowModal(false);
  //     setGroupIdToBeUpdated(null);
  //     setPasswordObject({});
  //     // setSelectedIncentive(null);
  //     toastr.success(updateGroupResponse.message);
  //   } else if (updateGroupResponse.type === "failure") {
  //     toastr.error(error.data.message, updateGroupResponse.message);
  //   }
  // }, [updateGroupResponse]);
  const fetch_localbody_code = (id, item) => {
    let groupname = "";
    if (item.group_name) {
      let group = item.group_name.split("/");
      groupname = group[1];
    }
    axios
      .get(`${API_URL}localbody/list` + "/" + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          setLocalbodycode(result);
          if (result.short_code) setGroupcode(`${result.short_code}/${groupname}`);
        }
      });
  };
  let preUpdateGroup = (item) => {
    if (item.group_name) {
      let group = item.group_name.split("/");
      setGroupname(group[1]);
    }

    if (item.group_chart_id) {
      let upi_id = item?.group_chart_id;
      setUpiname(upi_id);
    }

    let chrt = {
      label: item?.group_chart?.acc_chart_name,
      value: item?.group_chart?._id,
    };

    if (!item?.group_chart?.acc_chart_name) setSelectedChart(null);
    else setSelectedChart(chrt);

    if (item.group_localbody_name_id) {
      let localbodyname = {
        label: item.group_localbody_name_id.localbody_name,
        value: item.group_localbody_name_id._id,
      };
      setselectedLocalbody(localbodyname);
      fetch_localbody_code(localbodyname.value, item);
      dispatch(getWardOptions(localbodyname?.value));
      // handleSelectedLocalbody(localbodyname);
    }
    if (item.group_ward) {
      var result = item.group_ward.map(function (itm) {
        return { label: itm.ward_name, value: itm._id };
      });

      handleSelectedWard(result);
    }
    if (item.group_district) {
      let district = {
        label: item.group_district.district_name,
        value: item.group_district._id,
      };
      handleChangeDistrict(district);
    }
    if (item.group_incentive) {
      let incentive = {
        label: item.group_incentive.incentive_name,
        value: item.group_incentive._id,
      };
      setSelectedIncentive(incentive);
      item.incentive = incentive.value;
    } else {
      setSelectedIncentive(null);
      item.incentive = "";
    }
    setGroupIdToBeUpdated(item._id);
    setgroupObject({ ...item, password: null });
  };

  let preUpdateLocalbodyPassword = (item) => {
    setGroupIdToBeUpdated(item._id);
    setShowModal(true);
  };

  //   let preUpdateUser = (item) => {
  //     if (item.privilage) {
  //       let privilage = {
  //         label: item.privilage.name,
  //         value: item.privilage._id,
  //       };
  //       handleSelectedPrivilage(privilage);
  //     }
  //     if (item.company) {
  //       let company = {
  //         label: item.company.name,
  //         value: item.company._id,
  //       };
  //       handleSelectedCompany(company);
  //     }
  //     if (item.branch) {
  //       let branch = {
  //         label: item.branch.name,
  //         value: item.branch._id,
  //       };
  //       handleSelectedBranch(branch);
  //     }

  //     setUserIdToBeUpdated(item._id);
  //     setUserObject({ ...item, password: null });
  //   };

  //   let preUpdateUserPassword = (item) => {
  //     setUserIdToBeUpdated(item._id);
  //     setShowModal(true);
  //   };

  // useEffect(() => {
  //   let groupsData = [];
  //   groups?.map((item, index) => {
  //     item.action = (
  //       <div style={{ display: "flex", justifyContent: "center" }}>
  //         {/* <i
  //           className="uil-key-skeleton"
  //           style={{ fontSize: "1.3em", cursor: "pointer" }}
  //           onClick={() => {
  //             preUpdateUserPassword(item);
  //           }}
  //         ></i> */}
  //         <i
  //           className="uil-edit-alt"
  //           style={{
  //             fontSize: "1.2em",
  //             cursor: "pointer",
  //             marginLeft: "0.5rem",
  //             marginRight: "0.5rem",
  //           }}
  //           onClick={() => {
  //             toTop();
  //             preUpdateGroup(item);
  //           }}
  //         ></i>
  //         <i
  //           className="uil-trash-alt"
  //           style={{ fontSize: "1.2em", cursor: "pointer" }}
  //           onClick={() => {
  //             setGroupIdToBeDeleted(item._id);
  //             setConfirmDeleteAlert(true);
  //           }}
  //         ></i>
  //       </div>
  //     );
  //     if (item.group_ward != null) {
  //       item.ward = (
  //         <div style={{ display: "flex", justifyContent: "space-between" }}>
  //           {item.group_ward?.map((data) => {
  //             return <Chip size="small" label={data.ward_name}></Chip>;
  //           })}
  //         </div>
  //       );
  //     }
  //     item.id = index + 1;
  //     item.time = moment(item.group_time,"HHmmss").format("hh:mm a"); // 24hours
  //     item.date = moment(item.group_date).format("DD-MM-YYYY");
  //     //   item.name1 = `${item.firstName} ${item.lastName}`;

  //     //   item.privilage1 = item.privilage && item.privilage.name;
  //     //   item.company1 = item.company && item.company.name;
  //     //   item.branch1 = item.branch && item.branch.name;
  //     if (item.group_localbody_name_id != null) {
  //       item.localbody = item.group_localbody_name_id.localbody_name;
  //     }
  //     if (item.group_addedby != null) {
  //       item.addedby = item.group_addedby.username;
  //     }
  //     groupsData.push(item);
  //   });
  //   setgroupDataForTable(groupsData);
  // }, [groups]);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time	",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Group Name	",
        field: "group_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Localbody",
        field: "localbody",
        width: 300,
      },
      {
        label: "Ward",
        field: "ward",
        width: 300,
      },
      {
        label: "Incentive",
        field: "incentiveName",
        width: 300,
      },
      {
        label: "Staff",
        field: "addedby",
        width: 300,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: groupDataForTable,
  };

  //   let privilagesOptionsData =
  //     privilagesOptions &&
  //     privilagesOptions.data &&
  //     privilagesOptions.data.map((item) => {
  //       return {
  //         label: item.name,
  //         value: item._id,
  //       };
  //     });

  //   let companiesOptionsData =
  //     companiesOptions &&
  //     companiesOptions.data &&
  //     companiesOptions.data.map((item) => {
  //       return {
  //         label: item.name,
  //         value: item._id,
  //       };
  //     });

  //   let branchesOptionsData =
  //     branchesOptions &&
  //     branchesOptions.data &&
  //     branchesOptions.data.map((item) => {
  //       return {
  //         label: item.name,
  //         value: item._id,
  //       };
  //     });

  //   const privilagesOptionsGroup = [
  //     {
  //       options: privilagesOptionsData,
  //     },
  //   ];

  //   const companiesOptionsGroup = [
  //     {
  //       options: companiesOptionsData,
  //     },
  //   ];

  //   const branchesOptionsGroup = [
  //     {
  //       options: branchesOptionsData,
  //     },
  //   ]

  //   function handleChangeUser(e) {
  //     let name = e.target.name;
  //     let value = e.target.value;
  //     setUserObject({ ...userObject, [name]: value });
  //   }
  function code() {
    let code;
    if (localbody === {} && groupname === "") {
      code = "";
    } else code = `${localbody.short_code}/${groupname}`;
    return code;
  }
  const fetch_localbody = (id) => {
    axios
      .get(`${API_URL}localbody/list` + "/" + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          let result = res.data.data;
          setLocalbodycode(result);
          if (result.short_code) setGroupcode(`${result.short_code}/${groupname}`);
        }
      });
  };
  function handleSelectedLocalbody(value) {
    setselectedLocalbody(value);
    let newValue = {
      name: value.label,
      _id: value.value,
    };
    fetch_localbody(value.value);
    // dispatch(getLocalBody(value.value));
    dispatch(getWardOptions(value.value));
    setErrors({
      ...errors,
      ["localbodyError"]: "",
    });
    setSelectedWard(null);
    if (groupIdTobeUpdated) setgroupObject({ ...groupObject, group_localbody_name_id: newValue });
    else setgroupObject({ ...groupObject, localbody: newValue });
  }
  // useEffect(() => {
  //   if (localbody.short_code)
  //     setGroupcode(`${localbody.short_code}/${groupname}`);
  // }, [localbody, groupname]);
  // useEffect(() => {
  //   setgroupObject({ ...groupObject, group_name: groupcode });
  // }, [groupcode]);
  function handleChangeDistrict(value) {
    setselectedDistrict(value);
    let newValue = {
      name: value.label,
      _id: value.value,
    };
    setErrors({
      ...errors,
      ["districtError"]: "",
    });
    dispatch(getLocalbodyOptions(value.value));
    setgroupObject({ ...groupObject, district: newValue });
  }

  function handleSelectedWard(values) {
    setSelectedWard(values);
    let ward = [];
    values?.map((v) => ward.push(v.value));
    // Object.assign({},ward)
    setErrors({
      ...errors,
      ["wardError"]: "",
    });
    if (groupIdTobeUpdated) setgroupObject({ ...groupObject, group_ward: ward });
    else setgroupObject({ ...groupObject, wards: ward });
  }

  function handleGroupname(e) {
    let name = e.target.name;
    let value = e.target.value;
    setGroupname(value);
    let code = `${localbodycode?.short_code ? localbodycode.short_code : ""}/${value}`;
    // let code = localbody?.short_code?localbody.short_code:""+"/"+e.target.value;
    setGroupcode(code);
    setgroupObject({ ...groupObject, [name]: value, group_name: code });
  }

  const handleValidSubmit = (event, values) => {
    if (selectedDistrict === null || selectedLocalbody === null || selectedWard === null) {
      let errorVal = errors;
      if (selectedDistrict === null) {
        errorVal.districtError = "Please select district";
      }
      if (selectedLocalbody === null) {
        errorVal.localbodyError = "Please select localbody";
      }
      if (selectedWard === null) {
        errorVal.wardError = "Please select ward";
      }
      setErrors({
        ...errorVal,
      });
    } else {
      if (groupIdTobeUpdated) {
        axios
          .put(`${API_URL}group` + "/" + groupIdTobeUpdated, groupObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Group updated successfully");
              formRef.current.reset();
              setselectedLocalbody(null);
              setGroupname("");
              setUpiname("");
              setSelectedWard(null);
              setselectedDistrict(null);
              // setGroupcode(null);
              setGroupcode("");
              setErrors({
                districtError: "",
                localbodyError: "",
                wardError: "",
              });
              setSelectedIncentive(null);
              handleTableData();
              setGroupIdToBeUpdated(null);
              setLocalbodycode({});
            } else {
              toastr.error("Failed to update group");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}group`, groupObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Group added successfully");
              formRef.current.reset();
              setselectedLocalbody(null);
              setGroupname("");
              setUpiname("");
              setSelectedWard(null);
              setselectedDistrict(null);
              // setGroupcode(null);
              setGroupcode("");
              setErrors({
                districtError: "",
                localbodyError: "",
                wardError: "",
              });
              setSelectedIncentive(null);
              handleTableData();
              setLocalbodycode({});
            } else {
              toastr.error("Failed to add group");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
      // groupIdTobeUpdated
      //   ? dispatch(updateGroup(groupObject))
      //   : dispatch(addGroup(groupObject));
    }
  };
  //   function handleSelectedCompany(value) {
  //     let newValue = {
  //       name: value.label,
  //       _id: value.value,
  //     };
  //     setSelectedCompany(value);
  //     setUserObject({ ...userObject, company: newValue });
  //   }
  //   function handleSelectedBranch(value) {
  //     let newValue = {
  //       name: value.label,
  //       _id: value.value,
  //     };
  //     setSelectedBranch(value);
  //     setUserObject({ ...userObject, branch: newValue });
  //   }

  //   function handleChangePassword(e) {
  //     let name = e.target.name;
  //     let value = e.target.value;
  //     setPasswordObject({ ...passwordObject, [name]: value });
  //   }

  //   const handleValidSubmit = (event, values) => {
  //     userIdTobeUpdated
  //       ? dispatch(updateUser(userObject))
  //       : dispatch(addUser(userObject));
  //   };

  //   const handleValidSubmitPassword = (event, values) => {
  //     if (passwordObject.password == passwordObject.confirmPassword) {
  //       let item = {
  //         _id: userIdTobeUpdated,
  //         password: passwordObject.password,
  //       };
  //       dispatch(updateUser(item));
  //     } else {
  //       toastr.error("Passwords are not matching");
  //     }
  //   };

  //   let closeModal = () => {
  //     setShowModal(false);
  //     setUserIdToBeUpdated(null);
  //   };

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "incentives":
        setSelectedIncentive(selected);
        if (groupIdTobeUpdated) {
          let value = {
            incentive_name: selected.name,
            _id: selected.value,
          };
          setgroupObject({ ...groupObject, ["group_incentive"]: value });
        } else setgroupObject({ ...groupObject, ["incentive"]: selected.value });
        break;

      case "chart":
        setSelectedChart(selected);
        setgroupObject({
          ...groupObject,
          chart: selected.value,
        });
        break;

      default:
        break;
    }
  };

  function handleDelete() {
    if (groupname) {
      toastr.error("Are you sure you want to delete group name?");
    } else {
      return null;
    }
  }

  function handleChangeInput(e) {
    let name = e.target.name;
    let value = e.target.value;
    setUpiname(value);
    setgroupObject({ ...groupObject, [name]: value });
  }

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}group` + "/" + groupIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Group deleted successfully");

                  if (groupObject && groupObject._id === groupIdToBeDeleted) {
                    formRef.current.reset();
                    setselectedLocalbody(null);
                    setGroupname("");
                    setSelectedWard(null);
                    setselectedDistrict(null);
                    // setGroupcode(null);
                    setGroupcode("");
                    setErrors({
                      districtError: "",
                      localbodyError: "",
                      wardError: "",
                    });
                    setSelectedIncentive(null);
                    setGroupIdToBeUpdated(null);
                    setLocalbodycode({});
                  }
                  setGroupIdToBeDeleted(null);
                  handleTableData();
                } else {
                  toastr.error(res.data.message, "Failed to delete Group");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            // dispatch(deleteGroup(groupIdToBeDeleted));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Group" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>District</Label>
                          <Select
                            name="localbody_name"
                            value={selectedDistrict}
                            //   onChange={(value) => {
                            //     handleSelectedCommunities(value);
                            //   }}
                            options={districtOptions}
                            classNamePrefix="select2-selection"
                            onChange={handleChangeDistrict}
                          />
                          <p className="text-danger" style={{ fontSize: "11px" }}>
                            {selectedDistrict === null ? errors.districtError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Localbody</Label>
                          <Select
                            name="localbody_name"
                            value={selectedLocalbody}
                            //   onChange={(value) => {
                            //     handleSelectedCommunities(value);
                            //   }}
                            options={localbodyOptions?.map((item) => {
                              return {
                                label: item.localbody_name,
                                value: item._id,
                                key: item._id,
                              };
                            })}
                            classNamePrefix="select2-selection"
                            onChange={handleSelectedLocalbody}
                          />
                          <p className="text-danger" style={{ fontSize: "11px" }}>
                            {selectedLocalbody === null ? errors.localbodyError : ""}
                          </p>
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Group Name</Label>
                          <AvField
                            name="groupname"
                            placeholder=""
                            type="text"
                            errorMessage="Enter Group name"
                            value={groupname}
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            onChange={handleGroupname}
                            onClick={handleDelete}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-2" style={{ paddingTop: "25px" }}>
                          <AvField
                            readOnly
                            name="groupcode"
                            placeholder=" group name"
                            type="text"
                            errorMessage=""
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={groupcode}
                          //onChange={handleChangegroupcode}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Ward</Label>
                          <Select
                            isMulti
                            name="ward_name"
                            value={selectedWard}
                            options={wardOptions?.map((ward) => {
                              return {
                                label: ward.ward_name,
                                value: ward._id,
                                key: ward._id,
                              };
                            })}
                            onChange={handleSelectedWard}
                          />
                          <p className="text-danger" style={{ fontSize: "11px" }}>
                            {selectedWard === null ? errors.wardError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Incentive</Label>
                          <Select
                            name="incentive"
                            value={selectedIncentive}
                            onChange={(selected) => {
                              handleSelectChange(selected, "incentives");
                            }}
                            options={incentiveOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Chart</Label>
                          <Select
                            name="chart"
                            className="select2-selection"
                            value={selectedChart}
                            options={chartOptions}
                            onChange={(selected) => {
                              handleSelectChange(selected, "chart");
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>UPI ID</Label>
                          <AvField
                            name="group_upi_id"
                            value={upiname}
                            className="form-control"
                            placeholder="UPI ID"
                            type="text"
                            onChange={handleChangeInput}
                            validate={{
                              pattern: { value: "^[0-9A-Za-z.-]{2,256}@[A-Za-z]{2,64}$" },
                              required: { value: selectedChart, errorMessage: "Enter upi id" },
                            }}
                          />
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "28px" }}>
                          {groupIdTobeUpdated ? (
                            <Button color="primary" type="submit" disabled={addingGroup ? true : false}>
                              {addingGroup ? "Updating" : "Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit" disabled={addingGroup ? true : false}>
                              {addingGroup ? "Adding" : "Submit"}
                            </Button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable id="groupTableId" responsive bordered data={data} searching={true} info={true} disableRetreatAfterSorting={true} entries={20} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(connect(mapStateToProps, { apiError })(Group));

Group.propTypes = {
  error: PropTypes.any,
  groups: PropTypes.array,
};