import React, { useEffect, useRef, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";
import Creatable from "react-select/creatable";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { isArray } from "lodash";
import { Modal } from "react-bootstrap";
import Photo from "../../../assets/images/404-error.png"
const Complaints = () => {
  const [master, setMaster] = useState({});
  const [selected, setSelected] = useState({});
  const [openModal, setOpenModal] = useState(false);


  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleSelect = (name, e) => {
    const mastr = { ...master };
    const select = { ...selected };
    const value = e
      ? isArray(e)
        ? e.map((item) => item.value)
        : e.value
      : null;
      
    if (name === "complainant") {
      mastr.defendant = value === "Staff" ? "Customer" : "Staff";
      select.defendant =
        value === "Staff" ? { label: "Customer", value: "Customer" } : { label: "Staff", value: "Staff" };
    }

    mastr[name] = value;
    select[name] = e;
    setMaster(mastr);
    setSelected(select);
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "complaint_date",
        sort: "asc",
        width: 150,
      },

      {
        label: "Complainant",
        field: "complainant",
        sort: "asc",
        width: 150,
      },
      {
        label: "ID",
        field: "complaint_id",
        sort: "asc",
        width: 150,
      },

      {
        label: "Defendant",
        field: "defendant",
        sort: "asc",
        width: 150,
      },
      {
        label: "Category",
        field: "category",
        sort: "asc",
        width: "150",
      },

      // {
      //   label: "Mobile",
      //   field: "staff_mobile",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Localbody",
      //   field: "localbody",
      //   sort: "asc",
      //   width: 150,
      // },

      {
        label: "Ward",
        field: "ward",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Group",
      //   field: "group",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Priority",
        field: "complaint_priority",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "complaint_activestatus",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: [
      {
        id: 1,
        complaint_date: "2024-08-01",
        complainant: "John Doe",
        complaint_id: "C123456",
        defendant: "ABC Corp.",
        category: "Noise",
        ward: "Ward 1",
        complaint_priority: "High",
        complaint_activestatus: "Pending",
        staff: "Jane Smith",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              onClick={() => {
                setOpenModal(true)
              }}
          
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2em",
                marginRight: "0.5em",
              }}
            ></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.5em",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        id: 2,
        complaint_date: "2024-08-02",
        complainant: "Alice Brown",
        complaint_id: "C123457",
        defendant: "XYZ Ltd.",
        category: "Waste",
        ward: "Ward 2",
        complaint_priority: "Medium",
        complaint_activestatus: "In Progress",
        staff: "Mark Johnson",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2em",
                marginRight: "0.5em",
              }}
            ></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.5em",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        id: 3,
        complaint_date: "2024-08-03",
        complainant: "Emily White",
        complaint_id: "C123458",
        defendant: "123 Enterprises",
        category: "Vandalism",
        ward: "Ward 3",
        complaint_priority: "Low",
        complaint_activestatus: "Resolved",
        staff: "Sarah Lee",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2em",
                marginRight: "0.5em",
              }}
            ></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.5em",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        id: 4,
        complaint_date: "2024-08-04",
        complainant: "Michael Green",
        complaint_id: "C123459",
        defendant: "LMN Inc.",
        category: "Traffic",
        ward: "Ward 4",
        complaint_priority: "High",
        complaint_activestatus: "Pending",
        staff: "Lisa Black",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2em",
                marginRight: "0.5em",
              }}
            ></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.5em",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        id: 5,
        complaint_date: "2024-08-05",
        complainant: "Sophia Black",
        complaint_id: "C123460",
        defendant: "PQR Solutions",
        category: "Health",
        ward: "Ward 5",
        complaint_priority: "Medium",
        complaint_activestatus: "In Progress",
        staff: "John Doe",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.2em",
                marginRight: "0.5em",
              }}
            ></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.1em",
                marginRight: "0.5em",
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
    ],
  };
  const complaintTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "complaint_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Complainant",
        field: "complainant",
        sort: "asc",
        width: 150,
      },
      {
        label: "Complaint ID",
        field: "complaint_id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Priority",
        field: "priority",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
    ],

  };
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Staff Complaint" />
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm className="needs-validation">
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Ward</Label>
                        <Select
                          name="ward"
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Group</Label>
                        <Select
                          name="complaint_group"
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Complainant</Label>
                        <Select
                          name="complainant"
                          value={selected.complainant}
                          onChange={(selected) => {
                            handleSelect("complainant", selected);
                          }}
                          options={[
                            { label: "Staff", value: "Staff" },
                            { label: "Customer", value: "Customer" },
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    {master.complainant === "Staff" ? (
                      <>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Staff</Label>
                            <Select
                              name="ward"
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      </>
                    ) : master.complainant === "Customer" ? (
                      <>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Customer</Label>
                            <Creatable
                              name="ward"
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Mobile No.</Label>
                            <AvField
                              name="ward"
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      </>
                    ) : null}
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Defendant</Label>
                        <Select
                          name="To"
                          value={selected.defendant}
                          onChange={(selected) => {
                            handleSelect("defendant", selected);
                          }}
                          options={[
                            { label: "Staff", value: "Staff" },
                            { label: "Customer", value: "Customer" },
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    {master.defendant === "Staff" ? (
                      <>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Staff</Label>
                            <Select
                              name="ward"
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      </>
                    ) : master.defendant === "Customer" ? (
                      <>
                        {" "}
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Customer</Label>
                            <Creatable
                              name="ward"
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Mobile No.</Label>
                            <AvField
                              name="ward"
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      </>
                    ) : null}
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Complaint Category</Label>
                        <Select
                          name="category"
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom01">Priority</Label>
                        <Select
                          name="complaint_priority"
                          options={[
                            {
                              label: "Low",
                              value: 0,
                            },
                            {
                              label: "Medium",
                              value: 1,
                            },
                            {
                              label: "High",
                              value: 2,
                            },
                          ]}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom03">Image</Label>
                        <AvField
                          name="complaint_image"
                          type="file"
                          className="form-control"
                          id="validationCustom03"
                          // onChange={uploadImage}
                          rows="1"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom03">Upload Audio</Label>
                        <AvField
                          name="complaint_image"
                          type="file"
                          className="form-control"
                          id="validationCustom03"
                          rows="1"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom02">Comments</Label>
                        <AvField
                          name="complaint_comment"
                          placeholder="Comments"
                          type="textarea"
                          rows={1}
                          errorMessage="Add Comment"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom02"
                        />
                      </div>
                    </Col>
                    <Col style={{ paddingTop: "25px" }}>
                      <Button color="primary" className="me-2" type="submit">
                        {"Submit"}
                      </Button>
                      <Button color="danger" className="me-2" type="submit">
                        {"Reset"}
                      </Button>
                      <Button color="success" type="print">
                        Download
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
          className="mt-2"
        >
          <MDBDataTable
            id=""
            responsive
            bordered
            data={data}
            paging={false}
            searching={false}
          />
        </Row>
      </div>
      <Modal
              show={openModal}
              toggle={handleCloseModal}
              size="lg"
              centered={true}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0">Complaint </h5>
                <button
                  type="button"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <AvForm
                className="needs-validation"
              
              >
                <div className="modal-body">
                  <h5>Basic Details</h5>
                  <table
                    id="product_available_day"
                    className="table table-bordered dataTable"
                  >
                    <tr>
                    <td >Complainant :</td>
                      <td style={{ textAlign: "left" }}>
                      Aishwarya
                      </td>
                      <td style={{ padding: "10px" }}>Defendant :</td>
                      <td style={{ textAlign: "left" }}>Sreethi</td>
                     
                     
                    </tr>
                    <tr>
                    <td >Category :</td>
                      <td style={{ textAlign: "left" }}>
                      Technical problem
                      </td>
                      <td style={{ padding: "10px" }}>Ward :</td>
                      <td style={{ textAlign: "left" }}>	TMC7/SALAMATH NAGAR</td>
                    </tr>
                    <tr>
                    <td >Mobile :</td>
                      <td style={{ textAlign: "left" }}>
                      6282053987
                      </td>
                      <td >Audio :</td>
                      <td style={{ textAlign: "left" }}>
                      <i className="fas fa-file-audio"></i>
                      </td>
                    </tr>
                    <tr>
                    
                    <td >Image :</td>
                      <td style={{ textAlign: "left" }}>
                        <img style={{width:"100px",height:"100px"}} src={Photo} alt="" />
                      </td>
                    </tr>
                 
                    
                  </table>
                </div>

                <Row>
                  <Col className="col-12">
                    <AvForm>
                      <Row style={{ paddingLeft: "15px" }}>
                        <Col md="4">
                          <div className="mb-3">
                            <Label>Status</Label>
                            <Select
                              name="followup_status"
                            
                              options={[
                                {
                                  label: "Solved",
                                  value: 1,
                                },
                                {
                                  label: "Unsolved",
                                  value: 2,
                                },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                            
                            </p>
                          </div>
                        </Col>
                        <Col md="5">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">Comments</Label>
                            <AvField
                              name="followup_comment"
                              placeholder="Comments"
                              type="textarea"
                              // errorMessage="Add Comment"
                              className="form-control"
                              // validate={{ required: { value: true } }}
                              id="validationCustom02"
                         
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                         
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                    <Row
                      style={{ paddingLeft: "15px", paddingRight: "15px" }}
                      className="mt-2"
                    >
                      <MDBDataTable
                        id="complaintPopupTableDataId"
                        responsive
                        bordered
                        data={complaintTableData}
                        paging={false}
                        searching={false}
                      />
                    </Row>
                  </Col>
                </Row>
              


              </AvForm>
             
             
            </Modal>
    </div>
  );
};

export default Complaints;
